import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaTrashAlt } from "react-icons/fa";
import { useMutation, useQuery } from "@tanstack/react-query";
import { motion } from "framer-motion";

import { TitleSection } from "../TitleSection";
import DropdownDots from "../FormElement/DropdownDots";
import { FilterPage } from "../table/FilterPage";
import { FilterQtyPage } from "../table/FilterQtyPage";
import {
  INotificationsResponse,
} from "../../types/INotification";
import { IFilterRequest } from "../../types/IFilter";
import { AuthContext } from "../../context/AuthContext";
import NotificationService from "../../services/notificationService";
import { formatDate } from "../../utils/format-date";
import { PageContainer } from "../PageContainer";
import config from "../../config/variables";
import LoaderCircle from "../FormElement/LoaderCircle";
import moment from "moment";

const defaultResult = {
  total: 0,
  pages: 0,
  rows: [],
};

const defaultFilters = {
  limit: config.filter_default_limit,
  page: 1,
  status: "all",
  query: "",
  sortBy: "createdAt",
  display_type: ['popup']
};

const columnData = ["Fecha de inicio", "Título", "CTA", "Fecha de vencimiento", "Enviados", "Leídos", "Acciones"];

export const PopupNotificationsView = () => {
  const navigate = useNavigate();
  const { role } = useContext(AuthContext);
  const [result, setResult] = useState<INotificationsResponse>(defaultResult);
  const [filters, setFilters] = useState<IFilterRequest>(defaultFilters);
  const [search, setSearch] = useState("");

  const { isLoading, data = defaultResult } = useQuery(
    ["notifications", filters],
    () => NotificationService.getAll(filters)
  );
  const deleteItems = useMutation((id: string) =>
    NotificationService.remove(id)
  );

  const searcher = async (e: any) => {
    setSearch(e.target.value);
    await setFilters({ ...filters, page: 1, query: e.target.value });
  };

  useEffect(() => {
    handleFilter(filters);
  }, []);

  const handleFilter = async (filter: IFilterRequest) => {
    await setFilters({ ...filters, ...filter });
  };

  const handleDelete = async (id: string) => {
    if (window.confirm("Está seguro de eliminar esta notificación?")) {
      await deleteItems.mutateAsync(id).then((res) => {
        getItems.mutateAsync().then((res) => setResult(res));
      });
    }
  };

  const handleNavigate = () => {
    navigate("/admin/popup/add");
  };

  return (
    <PageContainer>
      <div className="flex justify-between">
        <TitleSection>Pop Up</TitleSection>
        <div className="flex gap-2">
          <input
            type="text"
            placeholder="Search..."
            className="hover:text-black rounded-full border-[2px] border-black-100 px-4 py-1 font-bold  text-black-100"
            value={search}
            onChange={searcher}
          />
          <button
            onClick={handleNavigate}
            className="rounded-full border-[2px] border-black-100 px-12 py-2 font-bold text-black-100 hover:bg-black-100 hover:text-white"
          >
            Crear nueva notificación
          </button>
        </div>
      </div>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <LoaderCircle />
        </div>
      ) : (
        <table className="my-table-spacing h-full w-full border-separate border-spacing-y-2 gap-5 overflow-x-auto pt-5 text-left">
        <thead className=" divide-solid border ">
          <tr className=" pb-4 text-sm font-bold text-gray-100">
            {columnData.map((column, index) => (
              <th
                className="border-b-[1px] border-colorBorder pb-4"
                key={column + index}
              >
                {column}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.rows.map((row, i) => (
            <motion.tr
              key={i}
              layout
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              initial={{ opacity: 0.2 }}
              transition={{ type: "spring", stiffness: 100, bounce: 0 }}
              className={`h-16  rounded-xl text-start font-medium outline outline-[1px] outline-colorBorder`}
            >
              <td className="pl-4">{formatDate(row.createdAt)}</td>
              <td className="pl-4">{row.title}</td>
              <td className="pl-4">{row.popup_button_text}</td>
              <td className="pl-4">{row.expiration_date && moment(row.expiration_date).format('DD-MM-YYYY HH:mm')}</td>
              <td className="pl-4">{row.delivered_count}</td>
              <td className="pl-4">{row.opened_count}</td>
              <td className="pl-4">
                {role == "admin" && (
                  <DropdownDots
                    options={[
                      {
                        title: "Eliminar",
                        action: () => handleDelete(row.id),
                        icon: <FaTrashAlt size={23} />,
                      },
                    ]}
                  />
                )}
              </td>
            </motion.tr>
          ))}
        </tbody>
      </table>
      )}
      <div className="flex justify-between pt-10">
        <FilterPage
          handleFilter={handleFilter}
          total={result.total}
          limit={filters.limit}
          status={filters.status}
          currentPage={filters.page}
        />
        <FilterQtyPage handleFilter={handleFilter} status={filters.status} />
      </div>
    </PageContainer>
  );
};
