import { NavLink } from "react-router-dom";

import { IOption } from "../../../types_new/IOption";
import { isActiveLink } from "../../../utils_new/isActiveLink";

type AsideLinkProps = {
  option: IOption;
};

export const AsideLink = ({ option }: AsideLinkProps) => {
  return (
    <NavLink
      to={`/${option.value}`}
      className={({ isActive }) =>
        isActive || isActiveLink(option.value)
          ? "font-black text-black-200"
          : "font-medium text-black-200"
      }
      key={`${option.text}`}
    >
      <li className="flex items-center py-[14px] ps-3 text-sm hover:bg-gray-300">
        {option.text}
      </li>
    </NavLink>
  );
};
