import React, { useState, useContext, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { AuthContext } from "../../context/AuthContext";
import { AiOutlineLogout } from "react-icons/ai";
import { useNavigate } from "react-router";
import { AiOutlineLock } from "react-icons/ai";
import Restricted from "../../context/Restricted";
import { whiteList } from "../../utils/permissions.constant";
import variables from "../../config/variables";

export const Aside = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const { menu, logout, producer, user } = auth;
  const { changeClient } = useContext(AuthContext);
  // const { query } = useRouter();
  // const { id } = query;

  const checkActiveItem = (url?: string) => {
    return url && window.location.pathname.includes(url);
  };

  useEffect(() => {
    // console.log(id);
  }, []);

  const styles = {
    li: "flex items-center py-[14px] before:absolute before:left-0 before:hidden before:h-12  before:w-[0.4rem] before:bg-black-100 hover:before:block mb-2",
  };

  const menuAdminTicketera = [
    {
      title: "Eventos",
      slug: "admin/events",
    },
    {
      title: "Vendedores",
      slug: "admin/rrpp",
    },
    {
      title: "Reportes",
      slug: "admin/reports",
    },
    {
      title: "Secciones",
      slug: "admin/sections",
    },
    {
      title: "Reviews",
      slug: "admin/reviews",
    },
    {
      title: "Clientes",
      slug: "admin/producers",
      includePath: "admin/client/add",
    },
    {
      title: "Usuarios",
      slug: "admin/users",
      permission: "admin",
    },
    {
      title: "Órdenes",
      slug: "admin/orders",
    },
    {
      title: "Swap",
      slug: "admin/offers",
    },
    {
      title: "Reportados",
      slug: "admin/reported",
      permission: "admin",
    },
    {
      title: "Papelera",
      slug: "admin/recycler",
    },
    {
      title: "Feedback",
      slug: "admin/feedback",
    },
    {
      title: "Ayuda Usuarios",
      slug: "admin/user_help",
    },
  ];

  const menuAdminSocialMedia = [
    {
      title: "Novedades",
      slug: "admin/news",
    },
    {
      title: "Artistas",
      slug: "admin/artists",
    },
    {
      title: "Agenda",
      slug: "admin/schedules",
    },
    {
      title: "Labels",
      slug: "admin/labels",
    },
  ];

  if (auth.permissions.admin_access === "social_media")
    menuAdminSocialMedia.push({
      title: "Secciones",
      slug: "admin/sections",
    });

  const menuAdminOther = [
    {
      title: "Control de acceso",
      slug: "admin/access-control",
    },
    {
      title: "Notificaciones",
      slug: "admin/notifications",
    },
    {
      title: "Popup",
      slug: "admin/popup",
    },
    {
      title: "Configuraciones",
      slug: "admin/configs",
    },
    {
      title: "Puntos bombo",
      slug: "admin/points",
    },
    {
      title: "Administradores",
      slug: "admin/admins",
    },
    {
      title: "Historial de cambios",
      slug: "admin/changelog",
    },
    {
      title: "Terminos",
      slug: "admin/terms",
    },
    {
      title: "Privacidad",
      slug: "admin/privacy",
    },
  ];

  const menuCoproducer = [
    {
      title: "Productoras",
      slug: "client/producers",
      permission: "any",
    },
    // {
    //   title: "Vendedores",
    //   slug: "client/rrpp",
    //   permission: 'admin',
    // },
  ];

  const menuClient = [
    ...menuCoproducer,
    {
      title: "Permisos de acceso",
      slug: "client/users",
      permission: "admin",
    },
  ];

  const getMenuClient = (): Array<any> => {
    if (auth.role == "coproducer") {
      return menuCoproducer;
    }
    return menuClient;
  };

  const MenuAdmin = () => {
    return (
      <>
        {["super_admin", "ticketera"].includes(
          auth.permissions.admin_access
        ) ? (
          <div className="mb-4 border-b-[1px]">
            <p className="font-black text-gray-100">TICKETERA</p>
            {menuAdminTicketera.map((option) => (
              <NavLink
                to={`/${option.slug}`}
                className={({ isActive }) =>
                  isActive || checkActiveItem(option["includePath"])
                    ? "font-black text-black-200"
                    : "font-medium text-black-200"
                }
                key={`${option.title}`}
              >
                <li className={`${styles.li} `}>{option.title}</li>
              </NavLink>
            ))}
          </div>
        ) : (
          <></>
        )}
        {["super_admin", "social_media"].includes(
          auth.permissions.admin_access
        ) ? (
          <div className="mb-2 border-b-[1px]">
            <p className="font-black text-gray-100">CONTENIDO</p>
            {menuAdminSocialMedia.map((option) => (
              <NavLink
                to={`/${option.slug}`}
                className={({ isActive }) =>
                  isActive || checkActiveItem(option["includePath"])
                    ? "font-black text-black-200"
                    : "font-medium text-black-200"
                }
                key={`${option.title}`}
              >
                <li className={`${styles.li} `}>{option.title}</li>
              </NavLink>
            ))}
          </div>
        ) : (
          <></>
        )}
        {["super_admin"].includes(auth.permissions.admin_access) ? (
          <div className="mb-2 border-b-[1px]">
            <p className="font-black text-gray-100">OTROS</p>
            {menuAdminOther.map((option) => (
              <NavLink
                to={`/${option.slug}`}
                className={({ isActive }) =>
                  isActive || checkActiveItem(option["includePath"])
                    ? "font-black text-black-200"
                    : "font-medium text-black-200"
                }
                key={`${option.title}`}
              >
                <li className={`${styles.li} `}>{option.title}</li>
              </NavLink>
            ))}
          </div>
        ) : (
          <></>
        )}
      </>
    );
  };

  const MenuClient = () => {
    return (
      <>
        {getMenuClient().map((option) => (
          <Restricted to={option.permission} key={`${option.title}`}>
            <NavLink
              to={`/${option.slug}`}
              className={({ isActive }) =>
                isActive
                  ? "font-black text-black-200"
                  : "font-medium text-black-200"
              }
              key={`${option.title}`}
            >
              <li className={`${styles.li} `}>{option.title}</li>
            </NavLink>
          </Restricted>
        ))}
      </>
    );
  };

  const SubMenu = () => {
    return (
      <>
        <Restricted to="events">
          <NavLink
            to={`/client/${
              producer && producer.id ? `producers/${producer.id}/` : ""
            }events`}
            className={({ isActive }) =>
              isActive
                ? "font-black text-black-200"
                : "font-medium text-black-200"
            }
          >
            <li className={`${styles.li} `}>{"Eventos"}</li>
          </NavLink>
        </Restricted>
        <Restricted to="admin">
          <NavLink
            to={`/client/list`}
            className={({ isActive }) =>
              isActive
                ? "font-black text-black-200"
                : "font-medium text-black-200"
            }
          >
            <li
              className={`mb-2 h-12 flex-col  items-start before:absolute before:left-0 before:hidden  before:h-12 before:w-[0.4rem] before:bg-black-100 hover:before:block`}
            >
              <p>{"Clientes"}</p>
              <div className="mt-2 flex gap-1">
                <AiOutlineLock className="text-[#101011]" size={24} />
                <span className="text-xs">ENCRIPTADA</span>
              </div>
            </li>
          </NavLink>
        </Restricted>
        {whiteList.includes(auth?.user?.email) ? (
          <Restricted to="reports">
            <a
              className="cursor-pointer select-none font-medium text-black-200"
              href={`${variables?.reports_url}producer/${producer?.id}`}
            >
              <li className={`${styles.li} `}>{"Reportes"}</li>
            </a>
          </Restricted>
        ) : (
          <Restricted to="reports">
            <NavLink
              to={`/client/reports`}
              className={({ isActive }) =>
                isActive
                  ? "font-black text-black-200"
                  : "font-medium text-black-200"
              }
            >
              <li className={`${styles.li} `}>{"Reportes"}</li>
            </NavLink>
          </Restricted>
        )}
        <Restricted to="admin">
          <NavLink
            to={`/client/rrpp`}
            className={({ isActive }) =>
              isActive
                ? "font-black text-black-200"
                : "font-medium text-black-200"
            }
          >
            <li className={`${styles.li} `}>{"Vendedores"}</li>
          </NavLink>
        </Restricted>
        <Restricted to="admin">
          <NavLink
            to={`/client/validators`}
            className={({ isActive }) =>
              isActive
                ? "font-black text-black-200"
                : "font-medium text-black-200"
            }
          >
            <li className={`${styles.li} `}>{"Validadores"}</li>
          </NavLink>
        </Restricted>
        {/*<Restricted to="admin">
          <NavLink to={`/client/help`} className={({ isActive }) => isActive ? "font-black text-black-200" : "font-medium text-black-200"}>
                <li className={`${styles.li} `}>{'Costo de servicio'}</li>
          </NavLink>
        </Restricted>*/}
      </>
    );
  };

  const handleNavigate = () => {
    if (user && user.user_role && user.user_role == "coproducer") {
      if (
        user.permissions &&
        user.permissions &&
        user.permissions.admin_access != "super_admin" &&
        !user.permissions.admin &&
        user.permissions.events == false
      )
        navigate("/client/producers");
    } else {
      navigate("/client/producers");
    }
  };

  return (
    <aside className=" flex w-72 flex-col">
      {menu == "submenu" && (
        <div className="cursor-pointer" onClick={handleNavigate}>
          <h1 className="p-5 text-4xl font-bold">{producer?.name}</h1>
        </div>
      )}
      <ul className="flex h-full select-none flex-col justify-between pb-20 pl-10 pt-16 text-sm ">
        <div className="flex flex-col  ">
          {menu == "admin" && <MenuAdmin />}
          {menu == "client" && <MenuClient />}
          {menu == "submenu" && <SubMenu />}
        </div>
      </ul>
      <ul className="flex  select-none flex-col justify-between pb-20 pl-10 pt-16 text-sm">
        <li
          className={`${styles.li} h-full cursor-pointer gap-2 align-bottom`}
          onClick={logout}
        >
          <AiOutlineLogout className="text-[#101011] " size={24} />
          <span className="text-xl font-bold">Logout</span>
        </li>
      </ul>
    </aside>
  );
};
function useRouter(): { query: any } {
  throw new Error("Function not implemented.");
}
