import {
  ErrorOption,
  FieldPath,
  UseFormSetError,
  UseFormSetFocus,
} from "react-hook-form";
import { IUserResponse } from "../types/IUser";
import {extractVideoMetadata, extractImageMetadata} from 'metalyzer'

export function setFormErrorsFromServer(
  reason: any,
  setError: UseFormSetError<any>,
  setFocus: UseFormSetFocus<any> = () => {}
) {
  console.info("test");
  let inputFocus = null;
  for (let message of reason.response.data.message) {
    let inputName = message.split(" ")[0];
    setError(inputName, { message: message });
    if (inputFocus == null) inputFocus = inputName;
  }
  if (inputFocus) setFocus(inputFocus);
}

export const blobToFile = (theBlob: Blob): File => {
  let typeFile = theBlob.type;
  let time = new Date().getTime();
  let array = typeFile.split("/");
  let fileName = `${time}.${array[1]}`;
  return new File([theBlob], fileName, { lastModified: time, type: typeFile });
};

export const validateDimensionsFile = async (
  file: File,
  width: number,
  height: number,
  aspectRatio = 1.77
) => {
  let dimensionFile: any = await getDimensionsFile(file);

  const fileAspectRatio = dimensionFile.width / dimensionFile.height;
  const aspectRatioTolerance = 0.05; // Allow a 5% tolerance
  
  return (
    dimensionFile.width >= width &&
    dimensionFile.height >= height &&
    Math.abs(fileAspectRatio - aspectRatio) <= aspectRatioTolerance
  );
};

export const getDimensionsFile = async (file: any) => {
  try {

      let mediaDimensions;

      if (file.type.startsWith('image/')) {
        const metaData = await extractImageMetadata(file)
        mediaDimensions = {width: metaData.imageWidth, height: metaData.imageHeight}
      } else if(file.type.startsWith('video/')){
        const metaData = await extractVideoMetadata(file)
        mediaDimensions = {width: metaData.videoWidth, height: metaData.videoHeight}
      }
    return mediaDimensions
  } catch (error) {
    console.error('Error extracting metadata:', error);
    throw error;
  }
}

export const getYouTubeVideoIdFromUrl = (url: string) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
  const match = url.match(regExp);
  return match && match[2].length === 11 ? match[2] : undefined;
};

export const generateImageDefaultFromUrlYoutube = (url: string) => {
  const idVideo = getYouTubeVideoIdFromUrl(url);
  const defaultImg = `http://img.youtube.com/vi/${idVideo}/hqdefault.jpg`;
  return defaultImg;
};

export const resizeFile = (file: File, newWidth: number, newHeight: number) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (fe: any) => {
      const img = new Image();
      img.src = fe.currentTarget.result;
      img.onload = function (ie: any) {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.drawImage(img, 0, 0);
        }
        canvas.width = newWidth;
        canvas.height = newHeight;
        ctx = canvas.getContext("2d");
        if (ctx) {
          ctx.drawImage(img, 0, 0, newWidth, newHeight);
        }

        canvas.toBlob((blob) => resolve(blob));
      };
    };
    reader.onerror = (error) => reject(error);
  });

export function b64toBlob(dataURI: any) {
  var byteString = window.atob(dataURI.split(",")[1]);
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);

  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type: "image/jpeg" });
}

export const getFullNameOrNickname = (user?: IUserResponse) => {
  if (!user) return "-";
  if (user.user_role == "artist" && user.nickname) {
    return user.nickname;
  }
  return user.first_name ? `${user.first_name} ${user.last_name}` : "-";
};

export const getFullNameOrEmail = (user?: IUserResponse) => {
  if (!user) return "-";
  let result = "";
  if (user.first_name) {
    result += user.first_name;
    if (user.last_name) result += ` ${user.last_name}`;
  } else {
    result += user.email.toLowerCase();
  }
  return result;
};
