import { Outlet } from "react-router-dom";

import { Header } from "./header";
import { Footer } from "./footer";
import { AlertToastify } from "./alertToastify";
import { Aside } from "./aside";
import { SidebarInset, SidebarProvider, SidebarTrigger } from "../ui/sidebar";

export const MainLayout = () => {
  return (
    <>
      <AlertToastify />
      <SidebarProvider>
        <Aside />
        <SidebarInset>
          <header className="flex h-16 items-center justify-between border-b px-4">
            <SidebarTrigger />
            <Header />
          </header>
          <main>
            <div
              className="min-h-[100vh] w-full overflow-hidden border-l-[1px] border-colorBorder px-1 pt-10 xl:px-12"
              style={{ minWidth: "calc(100% - 288px)" }}
            >
              <Outlet />
            </div>
          </main>
          <Footer />
        </SidebarInset>
      </SidebarProvider>
    </>
  );
};
