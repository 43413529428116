import { useContext } from "react";

import { AsideLink } from "./asideLink";
import { AuthContext } from "../../../context/AuthContext";
import logo from "../../../assets/logo.svg";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarHeader,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarRail,
} from "@/components_new/ui/sidebar";
import {
  menuSuperAdmin,
  menuAdminTicketera,
  menuAdminSocialMedia,
} from "../constants/asideMenu";

export const Aside = ({ ...props }: React.ComponentProps<typeof Sidebar>) => {
  const auth = useContext(AuthContext);
  const { user } = auth;

  return (
    <Sidebar {...props}>
      <SidebarHeader>
        <SidebarMenu>
          <SidebarMenuItem>
            <SidebarMenuButton size="lg" asChild>
              <picture>
                <img src={logo} />
              </picture>
            </SidebarMenuButton>
          </SidebarMenuItem>
        </SidebarMenu>
      </SidebarHeader>
      <SidebarContent>
        <SidebarGroup>
          <SidebarMenu>
            {["super_admin", "ticketera"].includes(
              user.permissions.admin_access
            ) && (
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <span className="mt-4 font-black uppercase text-gray-100">
                    Ticketera
                  </span>
                </SidebarMenuButton>

                <SidebarMenuSub>
                  {menuAdminTicketera.map((option) => (
                    <SidebarMenuSubItem key={option.text}>
                      <SidebarMenuSubButton asChild>
                        <AsideLink option={option} key={option.text} />
                      </SidebarMenuSubButton>
                    </SidebarMenuSubItem>
                  ))}
                </SidebarMenuSub>
              </SidebarMenuItem>
            )}
          </SidebarMenu>
          <SidebarMenu>
            {["super_admin", "social_media"].includes(
              user.permissions.admin_access
            ) && (
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <span className="mt-4 font-black uppercase text-gray-100">
                    Contenido
                  </span>
                </SidebarMenuButton>

                <SidebarMenuSub>
                  {menuAdminSocialMedia.map((option) => (
                    <SidebarMenuSubItem key={option.text}>
                      <SidebarMenuSubButton asChild>
                        <AsideLink option={option} key={option.text} />
                      </SidebarMenuSubButton>
                    </SidebarMenuSubItem>
                  ))}
                </SidebarMenuSub>
              </SidebarMenuItem>
            )}
          </SidebarMenu>
          <SidebarMenu>
            {["super_admin"].includes(user.permissions.admin_access) && (
              <SidebarMenuItem>
                <SidebarMenuButton asChild>
                  <span className="mt-4 font-black uppercase text-gray-100">
                    Otros
                  </span>
                </SidebarMenuButton>

                <SidebarMenuSub>
                  {menuSuperAdmin.map((option) => (
                    <SidebarMenuSubItem key={option.text}>
                      <SidebarMenuSubButton asChild>
                        <AsideLink option={option} key={option.text} />
                      </SidebarMenuSubButton>
                    </SidebarMenuSubItem>
                  ))}
                </SidebarMenuSub>
              </SidebarMenuItem>
            )}
          </SidebarMenu>
        </SidebarGroup>
      </SidebarContent>
      <SidebarRail />
    </Sidebar>
  );
};
