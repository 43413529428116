import { useContext } from "react";
import { useNavigate } from "react-router";
import { AvatarIcon, Pencil1Icon, ArrowDownIcon } from "@radix-ui/react-icons";

import account from "../../assets/account.png";
import { AuthContext } from "../../context/AuthContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { Button } from "../ui/button";

export const Header = () => {
  const { role, logout, user } = useContext(AuthContext);
  const navigate = useNavigate();

  return (
    <nav className="flex h-16 items-center justify-center gap-4 border-b-[1px] border-colorBorder">
      <p className="text-black text-xs">{user.email}</p>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="ghost"
            className="flex h-8 w-8 p-0 data-[state=open]:bg-muted"
          >
            <img
              src={user.profile_picture ? user.profile_picture : account}
              alt="profile-picture"
              className="rounded-full object-cover"
              width="36px"
              height="36px"
            />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuItem
            className="flex cursor-pointer gap-1"
            onClick={logout}
          >
            <ArrowDownIcon />
            Logout
          </DropdownMenuItem>
          {role == "producer" && (
            <>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="flex cursor-pointer gap-1"
                onClick={() => navigate("/client/change_password")}
              >
                <Pencil1Icon />
                Cambiar Contraseña
              </DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem
                className="flex cursor-pointer gap-1"
                onClick={() => navigate("/client/profile")}
              >
                <AvatarIcon />
                Perfil
              </DropdownMenuItem>
            </>
          )}
        </DropdownMenuContent>
      </DropdownMenu>
    </nav>
  );
};
