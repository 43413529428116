import React, { FC, Fragment, useState } from "react";
import { Controller, useFormState, useFormContext } from "react-hook-form";
import icon from "../../../assets/imgIcon.svg";
import { FaTrashAlt } from "react-icons/fa";
import { validateDimensionsFile } from "../../../utils/form";
import { FormImageVideoProps } from "@/types/FormImageVideoProps";

const FormImageVideo: FC<FormImageVideoProps> = ({
  name,
  label,
  accept,
  maxWidth,
  maxHeight,
  onImageSelected = () => {},
}) => {
  const formState = useFormState();
  const formContext = useFormContext();
  const [selectedMedia, setSelectedMedia] = useState<any>(null);
  const [selectedType, setSelectedType] = useState<string>();

  const onUpload = async (e: any) => {
    e.preventDefault();
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    if (files) {
      let file;
      const validate = await validateDimensionsFile(
        files[0],
        maxWidth,
        maxHeight
      );
      if (validate) {
        file = URL.createObjectURL(files[0]);
        formContext.setValue(name, files[0]);
        if (["video/mp4"].includes(files[0].type)) {
          setSelectedType(() => "video");
          onImageSelected({ file, fileType: "video" });
        } else {
          setSelectedType(() => "image");
          onImageSelected({ file, fileType: "image" });
        }
        formContext.setError(name, {
          type: "custom",
          message: "",
        });

        setSelectedMedia(file);
      } else {
        formContext.setError(name, {
          type: "custom",
          message: `Tamaño permitido ${maxWidth} x ${maxHeight} con un ratio de 1.77 (ancho/alto)`,
        });
      }
    }
  };

  const getPreviewValueOrUrl = (value: any): string => {
    if (value instanceof Blob) {
      return URL.createObjectURL(value);
    }
    if (value) {
      return value;
    }
    return value;
  };

  const onLoad = (onChange: any) => {
    if (selectedMedia) {
      onChange(selectedMedia);
    }
  };

  const handleDeleteMedia = (onChange: any) => {
    onChange("");
    setSelectedMedia(null);
    onImageSelected({ file: "", fileType: "" });
  };

  return (
    <Controller
      name={name}
      render={({ field: { value, name, onBlur, ref, onChange } }) => (
        <Fragment>
          <div className="flex w-full flex-col justify-center">
            <h3 className="pb-3 font-medium text-gray-100">{label}</h3>
            <label htmlFor={`upload_${name}`} className="relative">
              <div className="relative flex h-[30vh] w-[30vw] cursor-pointer items-center justify-center border border-dashed border-[#E4E6E8] bg-[#F5F5FA]">
                {value ? (
                  <div className="h-full w-full">
                    {selectedType === "image" && (
                      <img
                        src={getPreviewValueOrUrl(value)}
                        className="h-full w-full object-cover"
                      />
                    )}
                    {selectedType === "video" && (
                      <video
                        autoPlay
                        loop
                        className="h-full w-full object-cover"
                        src={getPreviewValueOrUrl(value)}
                      />
                    )}
                  </div>
                ) : (
                  <img
                    src={icon}
                    width={"10%"}
                    height={"10%"}
                    onLoad={() => onLoad(onChange)}
                    style={{ maxWidth: "100%", maxHeight: "100%" }}
                  />
                )}
                {!!value && (
                  <div
                    className="absolute right-2 top-2 cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation(); 
                      handleDeleteMedia(onChange);
                    }}
                  >
                    <FaTrashAlt size={23} />
                  </div>
                )}
              </div>
              <input
                type="file"
                className={"hidden"}
                id={`upload_${name}`}
                name={name}
                onBlur={onBlur}
                ref={ref}
                onChange={(e) => onUpload(e)}
                accept={accept ? accept : ""}
              />
            </label>
            {formState.errors[name] && formState.errors[name]?.message && (
              <p className="text-red-600 dark:text-red-500 mt-2 text-sm text-red">
                {String(
                  formState.errors[name] ? formState.errors[name]?.message : ""
                )}
              </p>
            )}
          </div>
        </Fragment>
      )}
    />
  );
};

export default FormImageVideo;
