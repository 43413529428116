import { useContext } from "react";
import { Navigate, useRoutes } from "react-router-dom";

import ProtectedRoute from "../context/ProtectedRoute";
import { Layout } from "../layout/Layout";
import { LoginView } from "../components/login/LoginView";
import { AccessControlView } from "../components/access-control/AccessControlView";
import { NotFoundView } from "../components/notFound/NotFoundView";
import { ReportsView } from "../components/reports/ReportsView";
import { FormRrpp } from "../components/rrpp/Form";
import { ArtistsView } from "../components/artists/ArtistsView";
import { UsersView } from "../components/users/UsersView";
import { ProducersView } from "../components/producers/ProducersView";
import { EventsView } from "../components/events/EventsView";
import { AuthContext } from "../context/AuthContext";
import { ClientsView } from "../components/clients/ClientsView";
import { FormClient } from "../components/clients/FormClient";
import { ProducerCardView } from "../components/producersCard/producerCardView";
import { ReviewsView } from "../components/reviews/ReviewsView";
import { NotificationsView } from "../components/notifications/NotificationsView";
import { FormNotification } from "../components/notifications/formNotification/FormNotification";
import { CongratulationsNotification } from "../components/notifications/formNotification/CongratulationsNotification";
import { SectionsView } from "../components/sections/SectionsView";
import { SelectedEvent } from "../components/events/selectedEvent/SelectedEvent";
import { SelectedProducer } from "../components/producers/selected/SelectedProducer";
import { NewsView } from "../components/news/NewsView";
import { FormNews } from "../components/news/formNews/FormNews";
import { FormTerms } from "../components/terms/FormTerms";
import { FormPrivacy } from "../components/privacy/FormPrivacy";
import { LabelsView } from "../components/labels/LabelsView";
import { FormLabel } from "../components/labels/formLabel/FormLabel";
import { CongratulationsLabel } from "../components/labels/formLabel/CongratulationsLabel";
import { SellersView } from "../components/sellers/SellersView";
import { FormConfig } from "../components/configs/FormConfig";
import { SelectedUser } from "../components/users/selected/SelectedUser";
import { ScheduleView } from "../components/schedule/scheduleView";
import { RrppView } from "../components/rrpp/RrppView";
import { SelectedRrpp } from "../components/rrpp/selected/SelectedRrpp";
import { AddSellerView } from "../components/rrpp/addSeller/addSellerView";
import { NewSeller } from "../components/rrpp/NewSeller";
import { UserViewProducer } from "../components/usersCard/UserViewProducer";
import { NewUser } from "../components/usersCard/NewUser";
import { FormPassword } from "../components/clients/FormPassword";
import { FormConfigPoint } from "../components/points/FormConfig";
import { RecyclerView } from "../components/recycler/RecyclerView";
import { UserViewAdmins } from "../components/usersCard/UserViewAdmins";
import { ClientProfile } from "../components/producers/ClientProfile";
import { TableValidators } from "../components/validators/TableValidators";
import { FormValidator } from "../components/validators/FormValidator";
import { SelectedOrder } from "../components/events/selectedEvent/orderDetail/SelectedOrder";
import { OrderTicket } from "../components/users/selected/tickets/ticketsDetail";
import { Traceability } from "../components/users/selected/tickets/Traceability";
import { CommentsView } from "../components/comments/CommentsView";
import { FeedbackView } from "../components/helpUsers/FeedbackView";
import { CommentReportsView } from "../components/comments/CommentReportsView";
import { ArtistReviewView } from "../components/artists/ArtistReviewsView";
import { TableReported } from "../components/reported/TableReported";
import { UsersCounter } from "../components/usersCounter/UsersCounter";
import ExportCsv from "../components/events/selectedEvent/ExportCsv/ExportCsv";
import PERMISSIONS from "../utils/permissions.constant";
import ExportarMolinetesCsvDownload from "../components/Downloaders/ExportarMolinetesCsvDownload";

import { EventsPage } from "../components_new/events/eventsPage";
import { OffersPage } from "../components_new/offers/offersPage";
import { OfferPage } from "../components_new/offer/offerPage";
import { OrdersPage } from "../components_new/orders/ordersPage";
import { MainLayout } from "../components_new/layout/mainLayout";
import { PopupNotificationsView } from "@/components/popup-notifications/PopupNotificationsView";
import { CongratulationsPopupNotification } from "@/components/popup-notifications/formNotification/CongratulationsPopupNotification";
import { PopupFormNotification } from "@/components/popup-notifications/formNotification/PopupFormNotification";
// import { ChangeLogPage } from "../components_new/changelog/changeLogPage";

const Router = () => {
  const { role } = useContext(AuthContext);

  return useRoutes([
    { path: "/", element: <LoginView /> },
    {
      path: "/system/user_counter",
      element: role == "system" ? <UsersCounter /> : <Navigate to="/" />,
    },

    // rutas del nuevo backoffice
    {
      path: "admin",
      element: role === "admin" ? <MainLayout /> : <Navigate to="/" />,
      children: [
        {
          path: "events",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <EventsPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "offers",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <OffersPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "offers/:offer_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <OfferPage />
            </ProtectedRoute>
          ),
        },
        {
          path: "orders",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <OrdersPage />
            </ProtectedRoute>
          ),
        },
      ],
    },

    // rutas del viejo backoffice (si están comentadas es porque ya fueron rehechas)
    {
      path: "/admin",
      element: role == "admin" ? <Layout /> : <Navigate to="/" />,
      children: [
        {
          path: "producers",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <ProducersView />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <SelectedProducer />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/events",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <EventsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/events/:event_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <SelectedEvent />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/events/:event_id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <SelectedEvent edit={true} />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/events/:event_id/export",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <ExportCsv />
            </ProtectedRoute>
          ),
        },
        {
          path: "events/:event_id/exportMolinetes",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <ExportarMolinetesCsvDownload />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/validators",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <TableValidators />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/validators/add",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <FormValidator />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/validators/:id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <FormValidator />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/producer_producer/:producer_producer_id/rrpp",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <SellersView />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/producer_producer/:producer_producer_id/rrpp/:id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <FormRrpp />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/producer_producer/:producer_producer_id/rrpp/:seller_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <SelectedRrpp />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/producer_producer/:producer_producer_id/rrpp/new-seller",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <NewSeller />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/producer_producer/:producer_producer_id/rrpp/edit-seller/:seller_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <NewSeller />
            </ProtectedRoute>
          ),
        },
        {
          path: "producers/:producer_id/producer_producer/:producer_producer_id/rrpp/new-seller/:leader_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <NewSeller />
            </ProtectedRoute>
          ),
        },
        {
          path: "events/:event_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <SelectedEvent />
            </ProtectedRoute>
          ),
        },
        {
          path: "events/:event_id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <SelectedEvent edit={true} />
            </ProtectedRoute>
          ),
        },
        {
          path: "events/:event_id/export",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <ExportCsv />
            </ProtectedRoute>
          ),
        },
        {
          path: "events/:event_id/comments",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <CommentsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "events/:event_id/comments/:comment_id/reports",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <CommentReportsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "events/:event_id/rrpp/:seller_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <SelectedRrpp />
            </ProtectedRoute>
          ),
        },
        {
          path: "order/:order_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <SelectedOrder />
            </ProtectedRoute>
          ),
        },
        {
          path: "users",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <UsersView />
            </ProtectedRoute>
          ),
        },
        {
          path: "users/:user_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <SelectedUser handleSelectItem={() => {}} />
            </ProtectedRoute>
          ),
        },
        {
          path: "users/orderTicket/:order_id/:user_id_data",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <OrderTicket />
            </ProtectedRoute>
          ),
        },
        {
          path: "orderTicket/:order_ticket_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <Traceability />
            </ProtectedRoute>
          ),
        },
        {
          path: "client/add",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <FormClient handleCreateorEdit={() => {}} id="0" />
            </ProtectedRoute>
          ),
        },
        {
          path: "client/:client_id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <FormClient handleCreateorEdit={() => {}} id="0" />
            </ProtectedRoute>
          ),
        },
        {
          path: "rrpp",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <SellersView />
            </ProtectedRoute>
          ),
        },
        {
          path: "rrpp/:seller_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <SelectedRrpp />
            </ProtectedRoute>
          ),
        },
        {
          path: "rrpp/:id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <FormRrpp />
            </ProtectedRoute>
          ),
        },
        {
          path: "reports",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <ReportsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "reviews",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <ReviewsView />
            </ProtectedRoute>
          ),
        },
        { path: "sections", element: <SectionsView /> },
        {
          path: "reported",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <TableReported />
            </ProtectedRoute>
          ),
        },
        {
          path: "feedback",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <FeedbackView typeMessages={"feedback"} />
            </ProtectedRoute>
          ),
        },
        {
          path: "user_help",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <FeedbackView typeMessages={"help"} />
            </ProtectedRoute>
          ),
        },
        {
          path: "recycler",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <RecyclerView />
            </ProtectedRoute>
          ),
        },

        {
          path: "artists",
          element: (
            <ProtectedRoute to={PERMISSIONS.SOCIAL_MEDIA}>
              <ArtistsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "artists/:artist_id/reviews",
          element: (
            <ProtectedRoute to={PERMISSIONS.SOCIAL_MEDIA}>
              <ArtistReviewView />
            </ProtectedRoute>
          ),
        },
        {
          path: "news",
          element: (
            <ProtectedRoute to={PERMISSIONS.SOCIAL_MEDIA}>
              <NewsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "news/:id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.SOCIAL_MEDIA}>
              <FormNews />
            </ProtectedRoute>
          ),
        },
        {
          path: "news/:new_id/comments",
          element: (
            <ProtectedRoute to={PERMISSIONS.SOCIAL_MEDIA}>
              <CommentsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "news/:new_id/comments/:comment_id/reports",
          element: (
            <ProtectedRoute to={PERMISSIONS.SOCIAL_MEDIA}>
              <CommentReportsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "news/add",
          element: (
            <ProtectedRoute to={PERMISSIONS.SOCIAL_MEDIA}>
              <FormNews />
            </ProtectedRoute>
          ),
        },
        {
          path: "schedules",
          element: (
            <ProtectedRoute to={PERMISSIONS.SOCIAL_MEDIA}>
              <ScheduleView />
            </ProtectedRoute>
          ),
        },
        {
          path: "labels",
          element: (
            <ProtectedRoute to={PERMISSIONS.SOCIAL_MEDIA}>
              <LabelsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "labels/add",
          element: (
            <ProtectedRoute to={PERMISSIONS.SOCIAL_MEDIA}>
              <FormLabel />
            </ProtectedRoute>
          ),
        },
        {
          path: "labels/:label_id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.SOCIAL_MEDIA}>
              <FormLabel />
            </ProtectedRoute>
          ),
        },
        {
          path: "labels/congratulations",
          element: (
            <ProtectedRoute to={PERMISSIONS.SOCIAL_MEDIA}>
              <CongratulationsLabel />
            </ProtectedRoute>
          ),
        },

        {
          path: "notifications",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <NotificationsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "notifications/add",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <FormNotification />
            </ProtectedRoute>
          ),
        },
        {
          path: "notifications/congratulations",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <CongratulationsNotification />
            </ProtectedRoute>
          ),
        },
        {
          path: "popup",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <PopupNotificationsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "popup/add",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <PopupFormNotification />
            </ProtectedRoute>
          ),
        },
        {
          path: "popup/congratulations",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <CongratulationsPopupNotification />
            </ProtectedRoute>
          ),
        },
        {
          path: "access-control",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <AccessControlView />
            </ProtectedRoute>
          ),
        },
        {
          path: "configs",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <FormConfig />
            </ProtectedRoute>
          ),
        },
        {
          path: "points",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <FormConfigPoint />
            </ProtectedRoute>
          ),
        },
        {
          path: "admins",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <UserViewAdmins />
            </ProtectedRoute>
          ),
        },
        {
          path: "changelog",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              {/* <ChangeLogPage /> */}
              <>Changelog</>
            </ProtectedRoute>
          ),
        },
        {
          path: "admins/new-admin",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <NewUser roleGiven="admin" />
            </ProtectedRoute>
          ),
        },
        {
          path: "admins/:user_id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <NewUser roleGiven="admin" />
            </ProtectedRoute>
          ),
        },
        {
          path: "terms",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <FormTerms />
            </ProtectedRoute>
          ),
        },
        {
          path: "privacy",
          element: (
            <ProtectedRoute to={PERMISSIONS.SUPER_ADMIN}>
              <FormPrivacy />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: "/client",
      element: ["producer", "coproducer"].includes(role) ? (
        <Layout />
      ) : (
        <Navigate to="/" />
      ),
      children: [
        { path: "list", element: <ClientsView /> },
        { path: "producers", element: <ProducerCardView /> },
        { path: "producers/:producer_id/events", element: <EventsView /> },
        {
          path: "events",
          element: (
            <ProtectedRoute to={PERMISSIONS.EVENTS}>
              <EventsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "events/:event_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.EVENTS}>
              <SelectedEvent />
            </ProtectedRoute>
          ),
        },
        {
          path: "events/:event_id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.EVENTS}>
              <SelectedEvent edit={true} />
            </ProtectedRoute>
          ),
        },
        {
          path: "events/:event_id/export",
          element: (
            <ProtectedRoute to={PERMISSIONS.TICKETERA}>
              <ExportCsv />
            </ProtectedRoute>
          ),
        },
        {
          path: "events/:event_id/exportMolinetes",
          element: (
            <ProtectedRoute to={PERMISSIONS.REPORTS}>
              <ExportarMolinetesCsvDownload />
            </ProtectedRoute>
          ),
        },
        {
          path: "events/:event_id/rrpp/:seller_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <SelectedRrpp />
            </ProtectedRoute>
          ),
        },
        { path: "order/:order_id", element: <SelectedOrder /> },
        {
          path: "reports",
          element: (
            <ProtectedRoute to={PERMISSIONS.REPORTS}>
              <ReportsView />
            </ProtectedRoute>
          ),
        },
        {
          path: "rrpp",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <RrppView />
            </ProtectedRoute>
          ),
        },
        {
          path: "rrpp/add",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <FormRrpp />
            </ProtectedRoute>
          ),
        },
        {
          path: "rrpp/:seller_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <SelectedRrpp />
            </ProtectedRoute>
          ),
        },
        {
          path: "rrpp/:id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <FormRrpp />
            </ProtectedRoute>
          ),
        },
        {
          path: "rrpp/new-seller",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <NewSeller />
            </ProtectedRoute>
          ),
        },
        {
          path: "rrpp/edit-seller/:seller_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <NewSeller />
            </ProtectedRoute>
          ),
        },
        {
          path: "rrpp/new-seller/:leader_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <NewSeller />
            </ProtectedRoute>
          ),
        },
        {
          path: "addSeller",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <AddSellerView />
            </ProtectedRoute>
          ),
        },
        {
          path: "validators",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <TableValidators />
            </ProtectedRoute>
          ),
        },
        {
          path: "validators/add",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <FormValidator />
            </ProtectedRoute>
          ),
        },
        {
          path: "validators/:seller_id",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <SelectedRrpp />
            </ProtectedRoute>
          ),
        },
        {
          path: "validators/:id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <FormValidator />
            </ProtectedRoute>
          ),
        },
        {
          path: "users",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <UserViewProducer />
            </ProtectedRoute>
          ),
        },
        { path: "users/orderTicket/:order_id", element: <OrderTicket /> },
        {
          path: "users/new-user",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <NewUser roleGiven="" />
            </ProtectedRoute>
          ),
        },
        {
          path: "users/:user_id/edit",
          element: (
            <ProtectedRoute to={PERMISSIONS.ADMIN}>
              <NewUser roleGiven="" />
            </ProtectedRoute>
          ),
        },
        {
          path: ":client_id/edit",
          element: <FormClient handleCreateorEdit={() => {}} id="0" />,
        },
        { path: "change_password", element: <FormPassword /> },
        { path: "profile", element: <ClientProfile /> },
      ],
    },
    { path: "*", element: <NotFoundView /> },
  ]);
};

export default Router;
